const config = {
    item: {
        postType: 'unterlagen',
        category: null,
        searchPlaceholder: 'Unterlagen suchen',
        filterCategories: [
            {
                slug: 'organisationsform',
                labelKey: 'Ich suche...'
            },
            {
                slug: 'sportart',
                labelKey: 'Sportart'
            },
            {
                slug: 'js_ausbildung',
                labelKey: "J+S Ausbildung"
            },
            {
                slug: 'scg_ausbildung',
                labelKey: 'Swiss Cycling Guide Ausbildung'
            },
            {
                slug: 'kanton',
                labelKey: 'Kanton'
            },
            {
                slug: 'availability',
                labelKey: 'Verfügbarkeit'
            },
        ],
    }
}

export default config;