import React, { useState } from 'react';
import './image-upload.scss';
import { useTranslation } from 'react-i18next';

import {
    changeFileExtensionIfNeeded,
    iterativeImageCompression,
} from './image-helper';

export default function ImageUpload(props) {
    const { handleFileChange } = props;
    const [preview, setPreview] = useState('');
    const { t } = useTranslation('erfassen-seite');

    const handleFiles = async files => {
        if (files.length === 0) {
            setPreview('');
            return;
        }

        const file = files[0];

        if (file && file.type.startsWith('image/')) {
            const modifiedFile = changeFileExtensionIfNeeded(file);
            try {
                const compressedFile = await iterativeImageCompression(
                    modifiedFile,
                );

                handleFileChange(compressedFile);
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }

        const reader = new FileReader();
        reader.onload = e => {
            setPreview(e.target.result);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className='image-upload'>
            <div id='drop-area'>
                <p>{t('foto_drag_and_drop')}</p>
                <input
                    type='file'
                    id='fileElem'
                    multiple
                    accept='image/*'
                    onChange={e => handleFiles(e.target.files)}
                />
                <label
                    className='button'
                    htmlFor='fileElem'
                >
                    {t('foto_auswahl')}
                </label>
            </div>
            <div className='image-preview'>
                <div className='wrapper'>
                    {preview ? (
                        <img
                            src={preview}
                            alt='Image preview'
                        />
                    ) : (
                        <div>{t('Vorschau')}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
