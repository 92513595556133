import React, { useReducer } from 'react'

import '../styles/components/select-button.scss';

export default function SelectButton(props) {

    const { value, dispatch, ichSuche, ichBiete } = props;



    return (
        <div className="select-button">
            <input
                type="radio"
                value="suche"
                id="suche"
                checked={value === 'suche'}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: 'suche',
                        key: 'type',
                    })} />
            <label htmlFor="suche">
                {ichSuche}
            </label>

            <input
                type="radio"
                value="biete"
                id="biete"
                checked={value === 'biete'}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: 'biete',
                        key: 'type',
                    })} />
            <label htmlFor="biete">
                {ichBiete}

            </label>
        </div>
    )
}
