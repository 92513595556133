import React, { useReducer, useEffect, useState } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate, useParams, Link } from "react-router-dom";

import Header from '../../containers/header/Header';
import Footer from '../../containers/footer/Footer';

import ItemsPreview from './ItemsPreview';

import FilterGroup from '../../containers/filter-group/FilterGroup';

import config from '../../containers/filter-group/config';
import { FilterContext, useFilter } from '../../containers/filter-group/context/FilterContext'; // Import your FilterContext
import PreviewItems from '../../containers/filter-group/PreviewItems';

import client from '../../client';
import { readItems } from '@directus/sdk';

import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';


const texte = {
    de: {
        uebersicht: 'Übersicht',
        text: 'Möchtest du dich als J+S-Leiter/in, als Trainer/in oder als Swiss Cycling Guide im Radsport engagieren? Oder sucht dein Verein noch Leiter/innen für ein Trainingslager oder ein/e Betreuer/in für ein regelmässiges Training? Auf dieser Plattform findest du eine Übersicht. Und bei Bedarf kannst du einen eigenen Eintrag erfassen.',
        erfassungsregeln: 'Erfassungsregeln:',
        neuer_eintrag: 'Neuer Eintrag',
        ich_biete: "«Ich biete»:",
        ich_suche: "«Ich suche:",
        biete_text: "Hier kannst du als J+S-Leiter/in, Trainer/in oder Swiss Cycling Guide einen Eintrag erstellen, wenn du gerne Trainings geben möchtest und noch freie Zeit zur Verfügung hast.",
        suche_text: "Hier können alle Vereine, die im Radsport tätig sind, sowie selbstständige Swiss Cycling Guides und Swiss Cycling Guide Partnerbetriebe Inserate schalten, wenn Personal gesucht wird.",
        suche: "suche",
        biete: "biete",
        termine: "Termine",
        ausbildung: "Ausbildung",
        kontakt: "Kontakt",
        link1: process.env.PUBLIC_URL,
        link2: process.env.PUBLIC_URL + "/neuer-eintrag",
    },
    fr: {
        uebersicht: "Aperçu",
        text: "Tu souhaites t'engager dans le cyclisme en tant que moniteur/trice J+S, entraîneur/euse ou guide Swiss Cycling ? Ou est-ce que ton club cherche encore des moniteurs/trices pour un camp d'entraînement ou un-e accompagnateur/trice pour un entraînement régulier ? Sur cette plateforme, tu trouveras un aperçu. Et si tu le souhaites, tu peux également saisir ta propre recherche.",
        erfassungsregeln: "Règles d’enregistrement:",
        neuer_eintrag: "Nouvelle annonce",
        ich_biete: "«J'offre»:",
        ich_suche: "«Je cherche»:",
        biete_text: "ici, tu peux créer une annonce en tant que moniteur/trice J+S, entraîneur/euse ou guide Swiss Cycling si tu souhaites donner des entraînements et que tu as encore du temps libre à disposition.",
        suche_text: "ici, tous les clubs actifs dans le cyclisme ainsi que les guides indépendants de Swiss Cycling et les entreprises partenaires de Swiss Cycling Guide peuvent publier des annonces lorsqu'ils recherchent du personnel.",
        suche: "chercher",
        biete: "offre",
        termine: "Dates",
        ausbildung: "Formation",
        kontakt: "Contact",
        link1: process.env.PUBLIC_URL + "/fr",
        link2: process.env.PUBLIC_URL + "/fr/neuer-eintrag",
    }
}


export default function Boerse() {

    const navigate = useNavigate();

    const { setData, setFilterItems, visibleData } = useFilter();

    const { t } = useTranslation(['erfassen-seite', 'config']);
    let location = useLocation();

    const { id } = useParams();


    useEffect(() => {
        if (id) {
            navigate(`/de/modal/${id}`, { state: { background: location } });
        }
    }, [id])


    const getFilterItems = (data, key) => {
        if (key === 'organisationsform') {
            data.map((item) => {
                if (item[key] === null) {
                    item[key] = t('Leiterperson');
                }
            });
        }
        return data
            .filter((item) => item[key] !== undefined && item[key] !== null)
            .filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t[key] === value[key]
                ))
            )
            .map((item) => (
                { 'name': item[key], 'slug': item[key] }
            ));
    }



    const getFilterItemsFromArray = (data, key) => {
        const flattenedItems = data.reduce((acc, item) => {
            if (item[key] !== undefined && item[key] !== null) {
                if (item[key] === '') {
                    return acc;
                }
                if (!item[key].includes('[')) {
                    acc.push({ 'name': item[key], 'slug': item[key] });
                    return acc;
                }

                if (item[key].includes('[')) {
                    const arr = JSON.parse(item[key]);

                    arr.forEach(subItem => {
                        acc.push({ 'name': subItem, 'slug': subItem });
                    });
                } else {
                    acc.push({ 'name': item[key], 'slug': item[key] });
                }
            }
            return acc;
        }, []);

        return flattenedItems.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t['slug'] === value['slug']
            ))
        );;
    }



    useEffect(() => {

        const fetchData = async () => {


            /*
            const data = await client.request(readItems('angebote', { sort: ['-date_created'] }));
 
 
            let angeboteData = data.data.map((item) => ({
                ...item,
                ...JSON.parse(item.formdata)
            }));
 
            delete angeboteData.formdata;
 
*/

            // const postsData = await posts.readByQuery({ sort: ['-date_created'] });
            const postsData = await client.request(readItems('posts', { sort: ['-date_updated'], fields: '*, image.*', query: { status: { _neq: "deleted" } } }));
            const filteredPostData = postsData.filter((item) => item.status !== 'deleted');


            const tmpData = [
                ...filteredPostData,
                // ...angeboteData
            ]

            setData(tmpData);
            setFilterItems('sportart', getFilterItemsFromArray(tmpData, 'sportart'));
            setFilterItems('type', getFilterItems(tmpData, 'type'));
            setFilterItems('kanton', getFilterItems(tmpData, 'kanton'));
            setFilterItems('js_ausbildung', getFilterItemsFromArray(tmpData, 'js_ausbildung'));
            setFilterItems('scg_ausbildung', getFilterItemsFromArray(tmpData, 'scg_ausbildung'));
            setFilterItems('availability', getFilterItemsFromArray(tmpData, 'availability'));
            setFilterItems('organisationsform', getFilterItems(tmpData, 'organisationsform'));



        }

        fetchData()
            .catch(console.error);
    }, [])

    const filterCategories = config['item'].filterCategories.map(category => ({
        ...category,
        label: t(category.labelKey),
    }));


    return (<>
        <Header />

        <div className='add-new'>

            <div>
                <h1>{t('slogan')}</h1>
                <h2>{t('Übersicht')}</h2>

                <p>{t('erfassen_intro')}</p>
                <button className="button" onClick={() => navigate('neuer-eintrag')}>{t('Neuer Eintrag')}</button>

                {/* 

                <h2>{t('erfassungsregeln_titel')}</h2>
                <p>
                    <b>{t('erfassungsregeln_biete_fett')}</b> {t('erfassungsregeln_biete_text')}</p>
                <p>
                    <b>{t('erfassungsregeln_suche_fett')}</b>  {t('erfassungsregeln_suche_text')}
                </p>
                */}
            </div>

            <FilterGroup filterCategories={filterCategories} searchPlaceholder="Suche" />
            <PreviewItems data={visibleData} postType={'item'} />

        </div >
        <Footer />
    </>
    )
}
