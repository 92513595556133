import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { FilterProvider } from './containers/filter-group/context/FilterContext';
import { ErrorProvider } from './context/ErrorContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const headers = {
  'Cache-Control': 'no-store'
}

const transport = {
  headers: headers
}

const options = {
  transport: transport
}
const basename = process.env.REACT_APP_HOMEPAGE;

root.render(
  <React.StrictMode>
    <ErrorProvider>

      <FilterProvider>

        <BrowserRouter basename={basename}>
          <App />
        </BrowserRouter>
      </FilterProvider>

    </ErrorProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
