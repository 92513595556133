import React from 'react';
import Header from '../../containers/header/Header';
import Footer from '../../containers/footer/Footer';
export default function Impressum() {
    return (
        <>
            <Header />

            <div className='add-new'>
                <div style={{ marginTop: 30 }}>
                    <h1 className='page-heading condensed'>Impressum</h1>
                    <h2 className='wp-block-heading'>Betreiber der Website</h2>
                    <p>
                        <strong>Swiss Cycling</strong>
                        <br />
                        Sportstrasse 44
                        <br />
                        2540 Grenchen
                        <br />
                        Schweiz
                    </p>
                    <p>
                        <a href='mailto:breitensport@swiss-cycling.ch'>
                            breitensport@swiss-cycling.ch
                        </a>
                    </p>
                    <h3 className='wp-block-heading'>Haftungsausschluss</h3>
                    <p>
                        Swiss Cycling übernimmt keinerlei Gewähr hinsichtlich
                        der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
                        Zuverlässigkeit und Vollständigkeit der Informationen.
                    </p>
                    <p>
                        Haftungsansprüche gegen Swiss Cycling wegen Schäden
                        materieller oder immaterieller Art, welche aus dem
                        Zugriff oder der Nutzung bzw. Nichtnutzung der
                        veröffentlichten Informationen, durch Missbrauch der
                        Verbindung oder durch technische Störungen entstanden
                        sind, werden ausgeschlossen.
                    </p>
                    <p>
                        Alle Angebote sind unverbindlich. Swiss Cycling behält
                        es sich ausdrücklich vor, Teile der Seiten oder das
                        gesamte Angebot ohne gesonderte Ankündigung zu
                        verändern, zu ergänzen, zu löschen oder die
                        Veröffentlichung zeitweise oder endgültig einzustellen.
                    </p>
                    <h3 className='wp-block-heading'>Haftung für Links</h3>
                    <p>
                        Verweise und Links auf Webseiten Dritter liegen
                        ausserhalb unseres Verantwortungsbereichs. Es wird
                        jegliche Verantwortung für solche Webseiten abgelehnt.
                        Der Zugriff und die Nutzung solcher Webseiten erfolgen
                        auf eigene Gefahr des Nutzers oder der Nutzerin.
                    </p>
                    <h3 className='wp-block-heading'>Urheberrechte</h3>
                    <p>
                        Die Urheber- und alle anderen Rechte an Inhalten,
                        Bildern, Fotos oder anderen Dateien auf der Website
                        gehören ausschliesslich&nbsp;
                        <strong>Swiss Cycling</strong>&nbsp;oder den speziell
                        genannten Rechtsinhabern. Für die Reproduktion jeglicher
                        Elemente ist die schriftliche Zustimmung der
                        Urheberrechtsträger im Voraus einzuholen.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}
