import React, { useEffect } from 'react'
import './header.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function Header() {
    const { language } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('header');

    const { lang } = useParams();

    const mainNavItems = [
        { title: t("Swiss Cycling Jobbörse"), link: `/${lang}` },
        {
            title: t("Neuer Eintrag"), link: `/${lang}/neuer-eintrag`
        },
    ];

    function getUUID() {
        const urlParams = new URLSearchParams(window.location.search);
        let uuid = urlParams.get('uuid');

        if (!uuid) {
            uuid = localStorage.getItem('uuid');
        } else {
            localStorage.setItem('uuid', uuid);
        }

        return uuid;
    }

    const uuid = getUUID();



    const metaNavItems = [
        { title: t("Über Swiss Cycling"), link: "https://swiss-cycling.ch", target: "__blank" },
    ];

    if (uuid) {
        mainNavItems.push({ title: t("Eintrag bearbeiten"), link: `/${lang}/eintrag-bearbeiten` });
        metaNavItems.push({ title: t("Logout"), link: `/${lang}/login` });
    } else {
        metaNavItems.push({ title: t("Login"), link: `/${lang}/login` });
    }

    const languageItems = [
        { title: "DE", language: "de" },
        { title: "FR", language: "fr" },
    ];

    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);

        const newPath = location.pathname.replace(/^\/[a-z]{2}(\/|$)/, `/${language}$1`);
        navigate(newPath, { replace: true });
    };

    useEffect(() => {
        changeLanguage(lang);
    }, [lang]);



    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header--left">
                        <div className="meta-nav-wrapper">
                            <ul className="nav meta-nav">
                                {metaNavItems.map((item, index) => {
                                    return (
                                        <li key={index} className="item">
                                            <Link to={item.link} target={item.target}>{item.title}</Link>
                                        </li>
                                    )
                                }
                                )}
                                {languageItems.map((item, index) => {
                                    return (
                                        <li key={index} className="item language">
                                            <span onClick={() => changeLanguage(item.language)}>{item.title}</span>
                                        </li>
                                    )
                                }
                                )}
                            </ul>
                        </div>
                        <div className="main-nav-wrapper">
                            <ul className="nav main-nav">
                                {mainNavItems.map((item, index) => {
                                    return (
                                        <li key={index} className="item">
                                            <Link to={item.link}>{item.title}</Link>
                                        </li>
                                    )
                                }
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="header--right">
                        <a href="https://swiss-cycling.ch" target="__blank">
                            <img src={process.env.PUBLIC_URL + "/assets/Logo-Swiss-Cycling.svg"} alt="swiss cycling ausbildung logo" />
                        </a>
                    </div>

                </div>

            </header >
            {/* 
            <hr style={{ margin: 0, height: 0, border: 0, borderTop: '1px solid #d8d8d8', }}></hr>
*/}
        </>
    )
}
