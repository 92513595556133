import React, { useReducer, useEffect, useState } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate, useParams, Link } from "react-router-dom";


const texte = {
    de: {
        titel: 'Merci',
        uebersicht: 'Übersicht',
        text: 'Merci für den Eintrag, wir kontrollieren ihn und schalten ihn so bald wie möglich online.',
        neuer_eintrag: 'Neuer Eintrag',
        link1: "/",
        link2: process.env.PUBLIC_URL + "/neuer-eintrag",
    },
    fr: {
        titel: 'Merci',
        uebersicht: "Aperçu",
        text: "Merci pour l'annonce, nous allons la vérifier et la mettre en ligne dès que possible.",
        neuer_eintrag: "Nouvelle annonce",
        link1: process.env.PUBLIC_URL + "/fr",
        link2: process.env.PUBLIC_URL + "/fr/neuer-eintrag",
    }
}


export default function Success() {
    const navigate = useNavigate();

    const { language } = useParams();

    const lc = language === 'fr' ? 'fr' : 'de';

    const [items, setItems] = useState(null);



    return (<>

        <div className='add-new'>
            <div className="language-switcher">
                <Link to="/fr/success">fr</Link>|
                <Link to="/success">de</Link>
            </div>


            <h2>{texte[lc].titel}</h2>

            <p>{texte[lc].text}</p>
            <button className="button" onClick={() => navigate(texte[lc].link1)}>{texte[lc].uebersicht}</button>
            <button className="button" onClick={() => navigate(texte[lc].link2)}>{texte[lc].neuer_eintrag}</button>
        </div>
    </>
    )
}
