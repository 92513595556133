import Compressor from 'compressorjs';

const compressImage = (file, quality) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: quality,
            maxWidth: 2400,
            maxHeight: 2400,
            convertSize: 500000,
            success: (result) => {
                const compressedFile = new File([result], file.name, { type: result.type });
                resolve(compressedFile);
            },
            error: (error) => {
                reject(error);
                console.error('Image compression failed:', error);
            },
        });
    });
};

const iterativeImageCompression = async (inputFile) => {
    let counter = 10;
    let quality = 1;
    let size = inputFile.size / (1000 * 1000);
    let factor = 1;
    let maxSize = 0.8;
    let file;

    while (counter > 0 && size > maxSize) {
        counter--;
        quality = Math.round((quality - factor * 0.05) * 20) / 20;
        file = await compressImage(inputFile, quality);
        size = file.size / (1000 * 1000);
        factor = Math.max(Math.round(size / maxSize), 1);
    }

    return file ? file : inputFile;
}

function changeFileExtensionIfNeeded(file) {
    const currentFileName = file.name;
    const currentFileExtension = currentFileName.split('.').pop().toLowerCase();

    if (currentFileExtension === 'png') {
        const newFileName = currentFileName.replace(/\.png$/i, '.jpg');

        return new File([file], newFileName, { type: 'image/jpeg' });
    }

    return file;
}


export { compressImage, iterativeImageCompression, changeFileExtensionIfNeeded };