import React, { useState, useEffect } from 'react'

import './filter-item.scss';
import { useFilter } from './context/FilterContext';
import { ReactComponent as Add } from './Add.svg';
import { ReactComponent as Remove } from './Remove.svg';
import { useTranslation } from 'react-i18next';

export default function FilterItem(props) {
    const { label, filterKey } = props;
    const { dispatch, filterState } = useFilter(); // Access the context to set data
    const [filterItems, setFilterItems] = useState([]);
    const { t } = useTranslation(['config', 'Filter']);

    const open = props.isOpen;

    const toggleOpenState = () => {
        props.toggleOpen();
    };

    useEffect(() => {
        const tmpFilterItems = filterState[filterKey];
        if (tmpFilterItems?.length > 0) {
            const sortedFilterItems = tmpFilterItems.filter(item => item.label !== null).sort((a, b) => a.label.localeCompare(b.label));
            setFilterItems(sortedFilterItems);
        }
    }, [filterState])


    const defaultOrder = {
        availability: [
            "Wöchentlich",
            "Ca. alle zwei Wochen",
            "Monatlich",
            "Sporadisch als Aushilfe"
        ],
        js_ausbildung: [
            "Grundausbildung",
            "Weiterbildung 1",
            "Weiterbildung 2",
            "Weiterbildung 2 Leistungssport",
            "Trainerausbildung (BTA/DTA)",
            "Expertenausbildung"
        ],
        scg_ausbildung: [
            "Road Guide Basic",
            "MTB Guide Basic",
            "MTB Guide Advanced",
            "Mountainbikelehrerin / Mountainbikelehrer mit eidgenössischem Fachausweis"
        ]
    }


    useEffect(() => {

        if (defaultOrder[filterKey]) {
            filterItems.sort((a, b) => {
                return defaultOrder[filterKey].indexOf(a.label) - defaultOrder[filterKey].indexOf(b.label);
            });
        } else if (props.alphabetically) {

            const sortedFilterItems = filterItems.sort((a, b) => a.label.localeCompare(b.label));
            setFilterItems(sortedFilterItems);
        }
    }, [filterItems])


    const handleFilterChange = (event) => {
        const { value } = event.target;
        dispatch({ type: 'TOGGLE_ITEM', value: value, key: filterKey });
    };


    return (
        <div className={`filter-wrapper ${open ? 'active' : null}`}>

            <div className={`filter-item ${open ? 'active' : null}`} onClick={() => toggleOpenState()}>
                <span>{label}</span>
                <span>
                    {open ?
                        <Remove />
                        : <Add />}
                </span>
            </div>
            {
                open ?
                    <div className="filter-values">
                        <div className="filter-checkboxes">

                            {filterItems.map((item) => (
                                <label key={item.value}>
                                    <input
                                        type="checkbox"
                                        value={item.value}
                                        checked={item.visible}
                                        onChange={handleFilterChange}
                                    />
                                    {t(item.label)}
                                </label>
                            ))}
                        </div>
                        <div className="filter__button-wrapper">
                            <button className="button" onClick={() => toggleOpenState()}>{t('Anwenden')}</button>
                        </div>
                    </div>
                    : null
            }        </div>

    )
}
