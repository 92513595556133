import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const ErrorContext = createContext();

const ErrorProvider = ({ children }) => {
    const [err, setErr] = useState(null);
    const [msg, setMsg] = useState(null);
    const [visible, setVisible] = useState(false);

    // Use useMemo to memoize the context value objects
    const contextValue = useMemo(() => {
        const setErrorMessage = (message, duration = 5000) => {
            setErr(true);
            setMsg(message);
            setVisible(true);
            // Schedule clearing the message after the specified duration
            setTimeout(clearError, duration);
        };

        const setSuccessMessage = (message, duration = 5000) => {
            setErr(false);
            setMsg(message);
            setVisible(true);
            console.log(message);
            // Schedule clearing the message after the specified duration
            setTimeout(clearError, duration);
        };

        const clearError = () => {
            setErr(null);
            setVisible(false);
        };

        return { setErrorMessage, setSuccessMessage, visible, err, msg };
    }, [err, msg]);

    return (
        <ErrorContext.Provider value={contextValue}>
            {children}
        </ErrorContext.Provider>
    );
};

ErrorProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useError = () => {
    return useContext(ErrorContext);
};

export { ErrorProvider, useError };
