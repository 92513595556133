import React from 'react';
import Item from '../../pages/boerse/ItemSmall';
import { useTranslation } from 'react-i18next';

import './preview-container.scss';

export default function PreviewItems({ data, postType }) {
    const { t } = useTranslation('Filter');

    return (
        <div>
            <span className="number-of-games">
                {t('Anzahl')}: {data.length}
            </span>

            <div className='items-preview'>

                {data ? data.map((item) => {
                    if (item) {

                        return (
                            <Item key={item.id} item={item} />
                        )
                    } else {
                        return null;
                    }
                }

                ) : null}
            </div>

        </div>
    );
}
