import React, { useReducer, useEffect, useState } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate, useParams, Link } from "react-router-dom";

import Header from '../../containers/header/Header';
import Footer from '../../containers/footer/Footer';

import ItemsPreview from './ItemsPreview';

import FilterGroup from '../../containers/filter-group/FilterGroup';

import config from '../../containers/filter-group/config';
import { FilterContext, useFilter } from '../../containers/filter-group/context/FilterContext'; // Import your FilterContext
import PreviewItems from '../../containers/filter-group/PreviewItems';

import { useTranslation } from 'react-i18next';

export default function Boerse() {

    const { t } = useTranslation('erfassen-seite');

    const [data, setData] = useState(null);

    function getUUID() {
        const urlParams = new URLSearchParams(window.location.search);
        let uuid = urlParams.get('uuid');

        if (!uuid) {
            uuid = localStorage.getItem('uuid');
        } else {
            localStorage.setItem('uuid', uuid);
        }

        return uuid;
    }



    useEffect(() => {
        const uuid = getUUID();

        if (!uuid) {
            console.error('UUID is missing');
            return;
        }

        const fetchData = async () => {
            try {
                const payload = { uuid };

                const response = await fetch('https://api.swiss-cycling-jobboerse.ch/jobboerse/get', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const items = await response.json();

                console.log(items)

                const filteredItems = items.filter(item => item.status !== 'deleted');

                setData(items);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);







    return (<>
        <Header />

        <div className='add-new'>

            <h2>{t('Eintrag bearbeiten')}</h2>
            <p>{t('eintrag_bearbeiten_text')}</p>



            {data === null ? <p>{t('kein_eintrag_text')}</p> :
                <PreviewItems data={data} postType={'item'} />


            }

        </div >
        <Footer />
    </>
    )
}
